import React, {useContext, useState} from 'react'
import UserContext from '../context/user'
import {allEmployees} from '../../employees'
import {Typeahead} from 'react-bootstrap-typeahead'
import Header from '../components/header'
import {ReportReviewForm} from '../components/report-review'
import Section from '../components/section'
import {TypeForm, PeerReviewForm} from "../components/typeform"
import Alert from "react-bootstrap/alert"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'

const {findEmployeesForManager} = require('../../employees')

const ReportReviewPage = () => {
  const user = useContext(UserContext)
  const [selected, setSelected] = useState(null)
  const reports = findEmployeesForManager(user.username)

  return (
    <>
      <Header/>
      <br/>

      <div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
      Report Review
      </div>
      <br/>

      <Typeahead
          id={'employees'}
          labelKey={'name'}
          filterBy={['name', 'id', 'nickname']}
          options={reports}
          placeholder={`Enter a report's name to begin the review process...`}
          autoFocus={true}
          onChange={(selected) => {
            setSelected(selected[0])
          }}
      />
      <ReportReviewForm
        report={selected}
      />
    </>
  )
}

export default ReportReviewPage
